import { Box, Button, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { AppColours } from "../../helpers/colors";

function Pricing() {
  // Use the navigate hook to navigate to the checkout page
  const navigate = useNavigate();
  const handleButtonRoute = (): void => {
    navigate("/checkout");
  };

  // suscriptionList
  const subscriptionList = [
    {
      title: "Basic Monthly Plan",
      description: "Save up to 50% Monthly",
      price: "$69.50",
      discount: "$139",
      note: "*3 days free trial - No Credit Card Required",
    },
    {
      title: "Lifetime Access",
      description: "Enjoy up to 90% off (Save over $5,200!)",
      price: "$599",
      discount: "$5990",
      note: "*3 days free trial - No Credit Card Required",
    },
    {
      title: "Basic Yearly Plan",
      description: "Save up to 50% Yearly",
      price: "$695",
      discount: "$1668",
      note: "*3 days free trial - No Credit Card Required",
    },
  ];

  return (
    <Box
      component="section"
      marginY={12}
      id="subscription"
      sx={{
        backgroundColor: { xs: "none", sm: "#FAF9FB" },
        p: { xs: "0", sm: "5rem 2rem" },
      }}
    >
      <Typography
        variant="h6"
        component="h2"
        sx={{
          color: "#996000",
          fontWeight: "bold",
          marginTop: "6rem",
          textAlign: "center",
          backgroundColor: "#FFF6E5",
          width: "fit-content",
          margin: "auto",
          padding: "1.2rem 3rem",
          borderRadius: "1rem",
        }}
      >
        SUBSCRIPTION
      </Typography>

      <Typography
        variant="h4"
        component="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          marginY: "2rem",
        }}
      >
        Our Subscription Plans
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "1rem",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
        }}
      >
        {subscriptionList.map((subscription, index) => (
          <Box
            component="div"
            key={index}
            sx={{
              border: "1px solid #8898AA",
              borderRadius: "20px",
              padding: "2rem",
              backgroundColor: index === 1 ? "#550066" : "#ffffff",
              width: { xs: "100%", md: "auto" },
            }}
          >
            <Stack spacing={3}>
              <Typography
                component="p"
                sx={{
                  fontWeight: "500",
                  padding: "0.75rem 1.5rem",
                  backgroundColor: index === 1 ? "#FBE5FF" : "#FAF9FB",
                  width: "fit-content",
                  borderRadius: "15px",
                  color: index === 1 ? "#550066" : "#3A4450",
                }}
              >
                {subscription.description}
              </Typography>

              <Typography
                component="p"
                sx={{
                  fontWeight: "bold",
                  color: index === 1 ? "#FFD080" : "black",
                }}
              >
                {subscription.title}
              </Typography>

              <Typography
                component="p"
                sx={{
                  fontSize: "2rem", // Increase font size
                  display: "flex", // Align items horizontally
                  alignItems: "center",
                  gap: "0.5rem", // Add space between price and discount
                }}
              >
                <Box
                  component="span"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "2rem",
                    color: index === 1 ? "#FFD080" : "black",
                  }}
                >
                  {subscription.price}
                </Box>
                <Box
                  component="span"
                  sx={{
                    textDecoration: "line-through",
                    fontSize: "2rem",
                    color: "#D2D9DF",
                  }}
                >
                  ({subscription.discount})
                </Box>
              </Typography>

              <Typography
                sx={{
                  color: "#D2D9DF",
                }}
              >
                {subscription.note}
              </Typography>
            </Stack>
          </Box>
        ))}
      </Box>

      {/* Button and Link Section */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "3rem",
        }}
      >
        <Button
          variant="contained"
          onClick={handleButtonRoute}
          sx={{
            width: "26rem",
            marginBottom: "1rem",
            justifyItems: "center",
            borderRadius: "10px",
            color: AppColours.MainYellow,
            padding: "1rem 0",
          }}
        >
          I WANT TO SIGN-UP FOR FREE
        </Button>
        <br />
      </Box>

      <Link
        to="/checkout"
        style={{
          fontWeight: "bold",
          fontSize: "10px",
          display: "block",
          textAlign: "center",
        }}
      >
        *3 DAYS FREE TRIAL- NO CREDIT CARD REQUIRED
      </Link>

      {/* Features List */}
      <Box
        sx={{
          marginTop: "3rem",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Features you get include:
        </Typography>
        <Typography
          component="ol"
          sx={{
            paddingLeft: 4,
            listStyleType: "decimal",
          }}
        >
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Automated appointment bookings:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Reduce no-shows and increase revenue with our automated booking
              system.
            </Box>
          </Typography>
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Automatic slot generation:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Save time and effort by letting the app create available slots for
              your business.
            </Box>
          </Typography>
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Secure payment processing:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Get paid on time, every time, with our secure payment processing
              system.
            </Box>
          </Typography>
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Real-time booking management:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Easily manage your bookings, staff, and services with our
              intuitive app.
            </Box>
          </Typography>
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Cancellation protection:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Receive 20% of the service cost as a cancellation fee if a user
              cancels last-minute.
            </Box>
          </Typography>
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Instant payouts:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Get paid quickly and easily, with funds transferred to your
              account immediately after a booking is completed.
            </Box>
          </Typography>
          <Typography component="li" sx={{ marginBottom: 1 }}>
            <Box component="span" sx={{ fontWeight: "bold" }}>
              Custom business website:
            </Box>{" "}
            <Box component="span" sx={{ color: "#3A4450" }}>
              Share your booking page on social media or with clients, to
              increase visibility and showcase your staff, services, and
              address.
            </Box>
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
}

export default Pricing;
