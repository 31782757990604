import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { AppColours } from "../../helpers/colors";
import { useNavigate } from "react-router-dom";
import business1 from "../../assets/new-landing/Business-1.png";
import business2 from "../../assets/new-landing/Business-2.png";
import business3 from "../../assets/new-landing/Business-3.png";
import business4 from "../../assets/new-landing/Business-4.png";

const BusinessGuide = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Typography
        variant="h3"
        component="h1"
        sx={{
          textAlign: { xs: "left", md: "center" },
          fontWeight: "bold",
          marginY: { xs: 6, md: 8 },
        }}
      >
        You: “How Many Steps Will It Take To Create An Account With{" "}
        <Box component="span" sx={{ color: "#550066", fontWeight: "bold" }}>
          “Shareduled?”
        </Box>{" "}
        <br /> <br /> Answer: “It Takes Just Few Simple Steps!” See below
      </Typography>

      {/* Business Guide Steps */}
      <Typography
        variant="h4"
        component="h4"
        sx={{
          fontWeight: "bold",
          textAlign: { xs: "left", md: "center" },
          marginY: { xs: "2rem", md: "3rem" },
        }}
      >
        Business Setup Guide
      </Typography>

      {/* image with features section */}

      <Box
        component="div"
        sx={{
          display: "flex",
          border: "0.5px solid #C8C8C8",
          p: { xs: "0", sm: "0.5rem", md: "1rem" },
          borderRadius: "20px",
          marginBottom: "2rem",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "20px",
            width: "full",
            height: "auto",
            margin: "auto",
            backgroundImage: "linear-gradient(to bottom, #FBE5FF, #FFFFFF)",
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              padding: "2rem",
            }}
          >
            <Box
              component="img"
              src={business1}
              alt="Scheduling and Booking Management GIF"
              sx={{
                borderRadius: "2rem",
              }}
            />
          </Grid>

          <Grid
            item
            md={6}
            sx={{
              alignSelf: "center",
              p: { xs: "2rem 0.5rem 2rem 1rem", md: "2rem 0 2rem 1rem" },
            }}
          >
            <Typography
              variant="body1"
              component="p"
              color="#EA580B"
              fontWeight={600}
            >
              Step 1
            </Typography>
            <Typography variant="h5" component="h5" fontWeight={700}>
              Sign Up
            </Typography>
            <Typography component="h6" color={AppColours.Nuetral}>
              Download and install “Shareduled Business” App (Andriod and iOS)
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="a"
                href="https://play.google.com/store/apps/details?id=com.shareduledbusiness"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src="/google-play-badge-logo.svg"
                  alt="Download from Google Play"
                  sx={{ width: 200, height: "auto" }}
                />
              </Box>
              <Box
                component="a"
                href="https://apps.apple.com/ca/app/shareduled-business/id6736670210"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src="/app-store-apple-logo.svg"
                  alt="Download from App Store"
                  sx={{ width: 200, height: "auto" }}
                />
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        sx={{
          display: "flex",
          border: "1px solid #C8C8C8",
          p: { xs: "0", sm: "0.5rem", md: "1rem" },
          borderRadius: "20px",
          marginBottom: "2rem",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "20px",
            width: "full",
            height: "auto",
            margin: "auto",
            backgroundImage: "linear-gradient(to bottom, #FFF6E5, #FFFFFF)",
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              alignSelf: "center",
              p: { xs: "2rem 0.5rem 2rem 1rem", md: "2rem 0 2rem 1rem" },
            }}
          >
            <Typography
              variant="body1"
              component="p"
              color="#EA580B"
              fontWeight={600}
            >
              Step 2
            </Typography>
            <Typography variant="h5" component="h5" fontWeight={700}>
              Fill Form With Your Details (One Time Only)
            </Typography>
            <Typography component="h6" color={AppColours.Nuetral}>
              Input relevant details that will help you receive payment easily.{" "}
              <br />
              This includes your personal details, business details and
              verification.
            </Typography>
          </Grid>

          <Grid
            item
            md={6}
            sx={{
              padding: "2rem",
            }}
          >
            <Box
              component="img"
              src={business2}
              alt="Resource Sharing and Management gif"
              sx={{
                // border: "1px solid #996000",
                borderRadius: "20px",
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        sx={{
          display: "flex",
          border: "1px solid #C8C8C8",
          p: { xs: "0", sm: "0.5rem", md: "1rem" },
          borderRadius: "20px",
          marginBottom: "2rem",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "20px",
            width: "full",
            height: "auto",
            margin: "auto",
            backgroundImage: "linear-gradient(to bottom, #FBE5FF, #FFFFFF)",
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              padding: "2rem",
            }}
          >
            <Box
              component="img"
              src={business3}
              alt="Staff and Customer Management"
              sx={{
                // border: "1px solid #550066",
                borderRadius: "20px",
              }}
            />
          </Grid>

          <Grid
            item
            md={6}
            sx={{
              alignSelf: "center",
              p: { xs: "2rem 0.5rem 2rem 1rem", md: "2rem 0 2rem 1rem" },
            }}
          >
            <Typography
              variant="body1"
              component="p"
              color="#EA580B"
              fontWeight={600}
            >
              Step 3
            </Typography>
            <Typography variant="h5" component="h5" fontWeight={700}>
              Set Up Profile And Customize Your Branding
            </Typography>
            <Typography component="h6" color={AppColours.Nuetral}>
              Use our easy-to-navigate interface to customize your
              availabilities and services so that your Shareduled account
              matches with your branding
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="div"
        sx={{
          display: "flex",
          border: "1px solid #C8C8C8",
          p: { xs: "0", sm: "0.5rem", md: "1rem" },
          borderRadius: "20px",
          marginBottom: "2rem",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "20px",
            width: "full",
            height: "auto",
            margin: "auto",
            backgroundImage: "linear-gradient(to bottom, #FFF6E5, #FFFFFF)",
          }}
        >
          <Grid
            item
            md={6}
            sx={{
              alignSelf: "center",
              p: { xs: "2rem 0.5rem 2rem 1rem", md: "2rem 0 2rem 1rem" },
            }}
          >
            <Typography
              variant="body1"
              component="p"
              color="#EA580B"
              fontWeight={600}
            >
              Step 4
            </Typography>
            <Typography variant="h5" component="h5" fontWeight={700}>
              Lauch!
            </Typography>
            <Typography component="h6" color={AppColours.Nuetral}>
              Share the good news of Shareduled with your employees and clients.{" "}
              <br />
              That's all!
            </Typography>
          </Grid>

          <Grid
            item
            md={6}
            sx={{
              padding: "2rem",
            }}
          >
            <Box
              component="img"
              src={business4}
              alt="Resource Sharing and Management gif"
              sx={{
                // border: "1px solid #996000",
                borderRadius: "20px",
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Typography
        variant="h4"
        component="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          marginY: "3rem",
        }}
      >
        Ready to get started today?
      </Typography>

      <Typography
        component="p"
        sx={{
          textAlign: "center",
          marginY: "3rem",
          color: "#00000080",
          fontWeight: "bold",
        }}
      >
        Click the button below and create your unique account right away.
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "6rem",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            navigate("/checkout");
          }}
          sx={{
            width: "26rem",
            justifyItems: "center",
            borderRadius: "10px",
            color: AppColours.MainYellow,
            padding: "1rem 0",
          }}
        >
          I WANT TO CREATE MY ACCOUNT
        </Button>
        <br />
      </Box>
    </Container>
  );
};

export default BusinessGuide;
